import _ from '@/utils/lodash'
import account from './account'
import alerts from './alerts'
import apiErrors from './api-errors'
import cities from './cities'
import cloudCostManagement from './cloud-cost-management'
import credentials from './credentials'
import environment from './environment'
import errors from './errors'
import events from './events'
import externalBackends from './external-backends'
import forms from './forms'
import infra from './infra'
import inventory from './inventory'
import kpis from './kpis'
import lang from './lang'
import legal from './legal'
import licence from './licence'
import pipeline from './pipeline'
import pluralizations from './pluralizations'
import presentation from './presentation'
import quotas from './quotas'
import roles from './roles'
import routes from './routes'
import severities from './severities'
import sorts from './sorts'
import stacks from './stacks'
import terracost from './terracost'
import time from './time'
import workers from './workers'

const commonCycloidTerms = {
  Activity: 'Actividad',
  APIKey: 'Clave API',
  APIKeys: 'Claves API',
  Appearance: 'Apariencia',
  CatalogRepositories: 'Repositorio de catálogos',
  CatalogRepository: 'Repositorio del catálogo',
  ConfigRepositories: 'Repositorios de configuración',
  ConfigRepository: 'Repositorio de configuración',
  Credential: 'Credencial',
  Credentials: 'Credenciales',
  Dashboard: 'Panel de control',
  Details: 'Detalles',
  Environment: 'Entorno',
  Environments: 'Entornos',
  Events: 'Eventos',
  InfraImport: 'Infra Import',
  InfraPolicy: 'InfraPolicy',
  InfraPolicies: 'InfraPolicies',
  Inventory: 'Inventario',
  Member: 'Miembro',
  Members: 'Miembros',
  Metrics: 'Métricas',
  Organization: 'Organizacion',
  Organizations: 'Organizaciones',
  Project: 'Proyecto',
  Projects: 'Proyectos',
  Quota: 'Quota',
  Quotas: 'Quotas',
  Resource: 'Recurso',
  Resources: 'Recursos',
  ResourcePool: 'Grupo de recursos',
  ResourcePools: 'Grupos de recursos',
  Role: 'Rol',
  Roles: 'Roles',
  Stack: 'Stack',
  Stacks: 'Stacks',
  Team: 'Equipo',
  Teams: 'Equipos',
  TerraformBackend: 'Backend de Terraform',
}

for (const key in commonCycloidTerms) {
  const camelCaseKey = _.camelCase(key)
  commonCycloidTerms[camelCaseKey] = commonCycloidTerms[key].toLowerCase()
}

const es = {
  all: 'Todas',
  and: 'y',
  assignOwner: 'Asignar propietario',
  author: 'Autor',
  clone: 'Clonar',
  collapse: 'colapsar',
  comingSoon: 'próximamente',
  comparedToLastWeek: 'Comparado con la semana pasada',
  configuration: 'Configuración',
  configure: 'Configurar',
  date: 'Fecha',
  Default: 'Por defecto',
  development: 'desarrollo',
  disabled: 'Desactivada',
  editor: 'Editor',
  enabled: 'Activada',
  from: 'de',
  fullScreen: 'Pantalla completa',
  general: 'General',
  icon: 'Icono',
  importing: 'importando',
  invite: 'Invitar',
  learnMore: 'Aprenda más',
  maintainer: 'Mantenedor',
  name: 'Nombre',
  noOwner: 'sin propietario',
  or: 'o',
  others: 'Otros',
  owner: 'Propietario',
  preview: 'Vista previa',
  provider: 'Proveedor',
  ReadMore: 'Leer más',
  seeMore: 'ver más',
  Settings: 'Ajustes',
  Summary: 'Resumen',
  welcomeMsg: 'Bienvenido a la App de Cycloid',
  youAreUsing: 'Está utilizando',
  youDoNotHavePermissionsTo: 'No tiene permisos suficientes para',
  ...commonCycloidTerms,
  ...pluralizations,
}

export default {
  ...es,
  account: account.es,
  alerts: alerts.es,
  apiErrors: apiErrors.es,
  cities: cities.en,
  cloudCostManagement: cloudCostManagement.es,
  credentials: credentials.es,
  environment: environment.es,
  errors: errors.es,
  events: events.es,
  externalBackends: externalBackends.es,
  forms: forms.es,
  infra: infra.es,
  inventory: inventory.es,
  kpis: kpis.es,
  lang: lang.es,
  legal: legal.es,
  licence: licence.es,
  pipeline: pipeline.es,
  presentation: presentation.es,
  quotas: quotas.es,
  roles: roles.es,
  routes: routes.es,
  severities: severities.es,
  sorts: sorts.es,
  stacks: stacks.es,
  terracost: terracost.es,
  time: time.es,
  workers: workers.es,
}
