import _ from '@/utils/lodash'
import account from './account'
import alerts from './alerts'
import apiErrors from './api-errors'
import cities from './cities'
import cloudCostManagement from './cloud-cost-management'
import credentials from './credentials'
import environment from './environment'
import errors from './errors'
import events from './events'
import externalBackends from './external-backends'
import forms from './forms'
import infra from './infra'
import inventory from './inventory'
import kpis from './kpis'
import lang from './lang'
import legal from './legal'
import licence from './licence'
import pipeline from './pipeline'
import pluralizations from './pluralizations'
import presentation from './presentation'
import quotas from './quotas'
import roles from './roles'
import routes from './routes'
import severities from './severities'
import sorts from './sorts'
import stacks from './stacks'
import terracost from './terracost'
import time from './time'
import workers from './workers'

const commonCycloidTerms = {
  Activity: 'Activity',
  APIKey: 'API key',
  APIKeys: 'API keys',
  Appearance: 'Appearance',
  CatalogRepositories: 'Catalog repositories',
  CatalogRepository: 'Catalog repository',
  ConfigRepositories: 'Config repositories',
  ConfigRepository: 'Config repository',
  Credential: 'Credential',
  Credentials: 'Credentials',
  Dashboard: 'Dashboard',
  Environment: 'Environment',
  Environments: 'Environments',
  Events: 'Events',
  InfraImport: 'Infra Import',
  InfraPolicy: 'InfraPolicy',
  InfraPolicies: 'InfraPolicies',
  Inventory: 'Inventory',
  Member: 'Member',
  Members: 'Members',
  Metrics: 'Metrics',
  Organization: 'Organization',
  Organizations: 'Organizations',
  Project: 'Project',
  Projects: 'Projects',
  Quota: 'Quota',
  Quotas: 'Quotas',
  Resource: 'Resource',
  Resources: 'Resources',
  ResourcePool: 'Resource pool',
  ResourcePools: 'Resource pools',
  Role: 'Role',
  Roles: 'Roles',
  Stack: 'Stack',
  Stacks: 'Stacks',
  Team: 'Team',
  Teams: 'Teams',
  TerraformBackend: 'Terraform backend',
}

for (const key in commonCycloidTerms) {
  const camelCaseKey = _.camelCase(key)
  commonCycloidTerms[camelCaseKey] = commonCycloidTerms[key].toLowerCase()
}

const en = {
  all: 'All',
  and: 'and',
  assignOwner: 'Assign owner',
  author: 'Author',
  clone: 'Clone',
  collapse: 'collapse',
  comingSoon: 'coming soon',
  comparedToLastWeek: 'Compared to last week',
  configuration: 'Configuration',
  configure: 'Configure',
  date: 'Date',
  Default: 'Default',
  Details: 'Details',
  development: 'development',
  disabled: 'Disabled',
  editor: 'Editor',
  enabled: 'Enabled',
  from: 'from',
  fullScreen: 'Full screen',
  general: 'General',
  icon: 'Icon',
  importing: 'importing',
  invite: 'Invite',
  learnMore: 'Learn more',
  maintainer: 'Maintainer',
  name: 'Name',
  noOwner: 'no owner',
  or: 'or',
  others: 'Others',
  owner: 'Owner',
  preview: 'Preview',
  provider: 'Provider',
  ReadMore: 'Read more',
  seeMore: 'see more',
  Settings: 'Settings',
  Summary: 'Summary',
  welcomeMsg: 'Welcome to Cycloid App',
  youAreUsing: 'You are using',
  youDoNotHavePermissionsTo: 'You do not have sufficient permissions to',
  ...commonCycloidTerms,
  ...pluralizations,
}

export default {
  ...en,
  account: account.en,
  alerts: alerts.en,
  apiErrors: apiErrors.en,
  cities: cities.en,
  cloudCostManagement: cloudCostManagement.en,
  credentials: credentials.en,
  environment: environment.en,
  errors: errors.en,
  events: events.en,
  externalBackends: externalBackends.en,
  forms: forms.en,
  infra: infra.en,
  inventory: inventory.en,
  kpis: kpis.en,
  lang: lang.en,
  legal: legal.en,
  licence: licence.en,
  pipeline: pipeline.en,
  presentation: presentation.en,
  quotas: quotas.en,
  roles: roles.en,
  routes: routes.en,
  severities: severities.en,
  sorts: sorts.en,
  stacks: stacks.en,
  terracost: terracost.en,
  time: time.en,
  workers: workers.en,
}
