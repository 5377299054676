export default {
  en: {
    blueprint: 'Blueprint',
    catalogStatus: {
      allHint: 'Both public and private stacks',
      infraImportName: '@:InfraImport stacks',
      infraImportHint: 'Stacks imported with @:InfraImport',
      ownHint: `All your organization's stacks`,
      ownName: `Organization stacks`,
    },
    cloneInstructions: 'Clone the catalog repository on your local machine to copy or edit stacks. Then commit and push your changes to your target catalog repository.',
    gitRepo: 'Git repository',
    gitRepoHint: 'Connect an existing git repository. This repository has to contain at least a branch, even if empty.',
    clone: 'Clone',
    noResultsFound: {
      title: 'No results found',
      subtitle: 'Try changing your filters.',
    },
    noStacksFound: {
      title: 'No stacks found',
      subtitle: 'Let’s get started by creating a stack in this repository. Click the <strong>Create stack</strong> button or push code to the Git repository to add stacks manually.',
    },
    maintainer: {
      description: 'Designate a team as stack maintainer. Note: This attribute serves for informational purposes only.',
      label: 'Maintainer',
      noMaintainer: 'No maintainer',
      assignMaintainer: 'Assign maintainer',
    },
    visibility: {
      title: 'Access and accountability',
      description: 'Choose a visibility and assign maintainers to stacks  already present in the git repository. You can always change those later on for each stack.',
      label: 'Stack visibility',
      local: 'Local',
      localHint: 'Only accessible to users within this organization.',
      shared: 'Shared',
      sharedHint: 'Accessible to users within this organization and its child organizations.',
      sharedByParentOrgHint: 'Shared by a parent organization. Accessible to users within this organization and all its child organizations.',
      hidden: 'Hidden',
      hiddenHint: 'Not displayed in the stack list or during project creation. Users within this organization can still use it by manually revealing hidden stacks.',
      changeVisibility: 'Change visibility',
      set: 'Set visibility',
    },
  },
  es: {
    blueprint: 'Plantilla',
    catalogStatus: {
      allHint: 'Stacks públicos y privados',
      infraImportName: '@:InfraImport stacks',
      infraImportHint: 'Stacks importadas con @:InfraImport',
      ownHint: 'Todos los stacks de tu organizacion',
      ownName: 'Organizacion stacks',
    },
    cloneInstructions: 'Clone el repositorio del catálogo en su máquina local para copiar o editar stacks. Luego confirme y envíe sus cambios a su repositorio de catálogo de destino.',
    gitRepo: 'Repositorio git',
    gitRepoHint: 'Conectar un repositorio Git existente. Este repositorio debe contener al menos una rama, incluso si está vacía.',
    clone: 'Clonar',
    noResultsFound: {
      title: 'No se encontraron resultados',
      subtitle: 'Intenta cambiar tus filtros',
    },
    noStacksFound: {
      title: 'No se encontraron stacks',
      subtitle: 'Comencemos creando un stack en este repositorio. Haz clic en el botón <strong>Crear stack</strong> o sube código al repositorio Git para agregar stacks manualmente.',
    },
    maintainer: {
      description: 'Designar a un equipo como mantenedor del stack. Nota: Este atributo es solo para fines informativos.',
      label: 'Mantenedor',
      noMaintainer: 'Sin mantenedor',
      assignMaintainer: 'Asignar responsable',
    },
    visibility: {
      title: 'Acceso y responsabilidad',
      description: 'Elija una visibilidad y asigne mantenedores a los stacks ya presentes en el repositorio de git. Siempre puede cambiar esto más adelante para cada stack.',
      label: 'Visibilidad del stack',
      local: 'Local',
      localHint: 'Sólo accesible para usuarios dentro de esta organización.',
      shared: 'Compartido',
      sharedHint: 'Accesible para los usuarios dentro de esta organización y sus organizaciones secundarias.',
      sharedByParentOrgHint: 'Compartido por una organización principal. Accesible para los usuarios de esta organización y de todas sus organizaciones secundarias.',
      hidden: 'Oculto',
      hiddenHint: 'No se muestra en la lista de stacks ni durante la creación del proyecto. Los usuarios de esta organización pueden seguir usándola revelando manualmente los stacks ocultos.',
      changeVisibility: 'Cambiar visibilidad',
      set: 'Establecer visibilidad',
    },
  },
  fr: {
    blueprint: 'Modèle',
    catalogStatus: {
      allHint: 'Stacks publiques et privées',
      infraImportName: '@:InfraImport stacks',
      infraImportHint: 'Stacks importées avec @:InfraImport',
      ownHint: 'Toutes les stacks de votre organisation',
      ownName: `Stacks d'organisation`,
    },
    cloneInstructions: 'Clonez le dépot de catalogue sur votre machine locale pour copier ou modifier des stacks. Ensuite, validez et transférez vos modifications vers votre dépot de catalogue cible.',
    gitRepo: 'Dépôt git',
    gitRepoHint: 'Connectez un dépôt git existant. Ce dépôt doit contenir au moins une branche, même si elle est vide.',
    clone: 'Cloner',
    noResultsFound: {
      title: 'Aucun résultat trouvé',
      subtitle: 'Essayez de modifier vos filtres.',
    },
    noStacksFound: {
      title: 'Aucune stack trouvée',
      subtitle: 'Commençons par créer une stack dans ce dépôt. Cliquez sur le bouton <strong>Créer une nouvelle stack</strong> ou poussez du code vers le dépôt Git pour ajouter des stacks manuellement.',
    },
    maintainer: {
      description: 'Désigner une équipe comme responsable de la stack. Remarque : Cet attribut est uniquement à des fins informatives.',
      label: 'Mainteneur',
      noMaintainer: 'Pas de mainteneur',
      assignMaintainer: 'Assigner un responsable',
    },
    visibility: {
      title: 'Accès et responsabilité',
      description: 'Choisissez une visibilité et assignez des mainteneurs aux stacks déjà présentes dans le dépôt git. Vous pouvez toujours changer cela plus tard pour chaque stack.',
      label: 'Visibilité de la stack',
      local: 'Local',
      localHint: 'Accessible uniquement aux utilisateurs de cette organisation.',
      shared: 'Partagé',
      sharedHint: 'Accessible aux utilisateurs de cette organisation et de ses organisations enfants.',
      sharedByParentOrgHint: 'Partagé par une organisation parente. Accessible aux utilisateurs de cette organisation et de toutes ses organisations enfants.',
      hidden: 'Caché',
      hiddenHint: `Non affiché dans la liste des stacks ou lors de la création du projet. Les utilisateurs de cette organisation peuvent toujours l'utiliser en révélant manuellement les stacks masquées.`,
      changeVisibility: 'Changer la visibilité',
      set: 'Définir la visibilité',
    },
  },
}
