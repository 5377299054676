import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locales from '@/locales'
import _ from '@/utils/lodash'

Vue.use(VueI18n)

const { languages = [] } = global.navigator

export default new VueI18n({
  locale: languages.find((locale) => _.keys(locales).includes(locale)) || process.env.VUE_APP_DEFAULT_LANG,
  fallbackLocale: process.env.VUE_APP_DEFAULT_LANG,
  fallbackRoot: false,
  sync: true,
  silentTranslationWarn: true,
  messages: locales,
  missing (_locale, key) {
    if (!process.env.NODE_ENV === 'production') {
      console.warn(`error: missing locale ${_locale}.${key}`)
    }
  },
})
